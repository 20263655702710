import React, { Suspense, lazy } from 'react'
// import loadable from '@loadable/component'
import { Redirect, Route, Switch, BrowserRouter as Router } from 'react-router-dom'
import { useConfigureVhViewportMobile } from 'hooks'

import ActionRequestFormValidator from 'containers/actionRequestValidator'
import CallbackContainer from 'containers/callback'
import ViewError from 'components/globals/ViewError'
import Loading from 'components/globals/Loading'

import PrivateRoute from './PrivateRouter'

const IntroductionPageExternal = lazy(() => import(/* webpackChunkName: "IntroductionPageExternal" */ 'pages/_v2/open-test-external/introduction'))
const KFormContainer = lazy(() => import(/* webpackChunkName: "KFormContainer" */ 'containers/krowdy-form'))
const TestContainer = lazy(() => import(/* webpackChunkName: "TestContainer" */ 'containers/test'))
const OpenTestContainer = lazy(() => import(/* webpackChunkName: "OpenTestContainer" */ 'containers/open-test'))
const OpenTestContainerExternal = lazy(() => import(/* webpackChunkName: "OpenTestContainer" */ 'containers/open-test-external'))
const OpenTestExternal = lazy(() => import(/* webpackChunkName: "OpenTestExternal" */ 'pages/_v2/open-test-external'))
const PreOpenTestContainer = lazy(() => import(/* webpackChunkName: "PreOpenTestContainer" */ 'containers/open-test/Confirmation'))
const V2FormPage = lazy(() => import(/* webpackChunkName: "V2FormPage" */ 'pages/_v2/_form'))
const V2OpenTestPage = lazy(() => import(/* webpackChunkName: "V2OpenTestPage" */ 'pages/_v2/_open-test'))
const V2OpenTestIntroductionPage = lazy(() => import(/* webpackChunkName: "V2OpenTestIntroductionPage" */ 'pages/_v2/_open-test/_introduction'))
const V2IntroductionPage = lazy(() => import(/* webpackChunkName: "V2IntroductionPage" */ 'pages/_v2/_introduction'))
const V2EventSchedulePage = lazy(() => import(/* webpackChunkName: "V2EventSchedulePage" */ 'pages/_v2/_event-schedule'))

const Root = () => {
  useConfigureVhViewportMobile()

  return (
    <Router>
      <Switch>
        <Redirect exact from='/' to='/config' />
        <Route
          path='/test' render={() => (
            <Suspense fallback={<Loading />}>
              <TestContainer />
            </Suspense>
          )} />
        <Route
          path='/config'
          render={() => (
            <PrivateRoute>
              <Suspense fallback={<Loading />}>
                <OpenTestContainerExternal />
              </Suspense>
            </PrivateRoute>
          )} />
        <Route
          path='/new/:id'
          render={() => (
            <Suspense fallback={<Loading />}>
              <IntroductionPageExternal />
            </Suspense>
          )} />
        <Route
          path='/external/:codeTask/:openTestId'
          render={() => (
            <Suspense fallback={<Loading />}>
              <OpenTestExternal />
            </Suspense>
          )} />
        <Route
          path='/introduction'
          render={() => (
            <Suspense fallback={<Loading />}>
              <V2IntroductionPage />
            </Suspense>
          )} />
        <Route path='/krowdy-form/forbidden' render={() => <ViewError title='' />} />
        <Route
          path='/open-test/:openTestId'
          render={() => (
            <Suspense fallback={<Loading />}>
              <OpenTestContainer />
            </Suspense>
          )} />
        <Route
          path='/pre/open-test/:openTestId'
          render={() => (
            <Suspense fallback={<Loading />}>
              <PreOpenTestContainer />
            </Suspense>
          )} />
        <Route
          path='/krowdy-form/:candidateFormId' render={() => (
            <Suspense fallback={<Loading />}>
              <KFormContainer />
            </Suspense>
          )} >
        </Route>
        <Route
          exact path={[
            '/v2/form/:candidateFormId',
            '/v2/form-call/:candidateFormId'
          ]}
          render={() => (
            <PrivateRoute>
              <Suspense fallback={<Loading />}>
                <ActionRequestFormValidator>
                  <V2FormPage />
                </ActionRequestFormValidator>
              </Suspense>
            </PrivateRoute>
          )} />
        <Route
          exact path={[
            '/v2/open-test/:openTestId/introduction',
            '/v2/open-test-fuzzy-natura/:openTestId/introduction',
            '/v2/open-test-fuzzy-natura-competencies/:openTestId/introduction'
          ]}
          render={() => (
            <PrivateRoute>
              <Suspense fallback={<Loading />}>
                <ActionRequestFormValidator>
                  <V2OpenTestIntroductionPage />
                </ActionRequestFormValidator>
              </Suspense>
            </PrivateRoute>
          )} />
        <Route
          exact path={[
            '/v2/open-test/:openTestId',
            '/v2/open-test-fuzzy-natura/:openTestId',
            '/v2/open-test-fuzzy-natura-competencies/:openTestId'
          ]}
          render={() => (
            <PrivateRoute>
              <Suspense fallback={<Loading />}>
                <ActionRequestFormValidator>
                  <V2OpenTestPage />
                </ActionRequestFormValidator>
              </Suspense>
            </PrivateRoute>
          )} />
        <Route
          exact
          path={[
            '/event-schedule/:eventScheduleId',
            '/v2/event-schedule/:eventScheduleId'
          ]}
          render={() => (
            <Suspense fallback={<Loading />}>
              <ActionRequestFormValidator>
                <V2EventSchedulePage />
              </ActionRequestFormValidator>
            </Suspense>
          )} />
        <Route component={CallbackContainer} path='/callback' />
        <Route component={ViewError} />
      </Switch>
    </Router>
  )
}

// export default hot(module)(Root)
export default Root

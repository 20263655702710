import { KNOWLEDGE_ANSWER_FRAGMENT } from './fragment'
import produce from 'immer'

const getParamsAnswer = (answer) => {
  switch (answer.type) {
    case 'knowledge': {
      return {
        fragment: KNOWLEDGE_ANSWER_FRAGMENT,
        id      : `${answer.__typename}:${answer._id}`
      }
    }
    default: {
      return ''
    }
  }
}

export const KrowdyFormResolvers = {
  Mutation: {
    updateAnswer: (_, args, { cache }) => {
      try {
        const { answer , alternative } = args
        const { questionType } = answer
        switch (questionType) {
          case 'radioButton': {
            const params = getParamsAnswer(answer)

            cache.writeFragment({
              ...params,
              data: produce(cache.readFragment(params),(draft) => {
                draft.alternatives.forEach((alt) => {
                  if(alt.key === alternative.key)
                    alt.chosen = true
                  else
                    alt.chosen = false
                })
              })
            })
            break
          }
          default: {
            break
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-restricted-syntax
        console.log(error)
      }
    }
  }
}

import Axios from 'axios'
import { getCredentials } from './credentials'
import { AliasEnum } from './constants'
import qs from 'query-string'

export const randomOrderArray = (array) => {
  let currentIndex = array.length, temporaryValue, randomIndex

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }

  return array
}

export const mongoObjectId = () => {
  const timestamp = (new Date().getTime() / 1000 | 0).toString(16)

  return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, () => (Math.random() * 16 | 0).toString(16)).toLowerCase()
}

export const removeTypename = (value) => {
  if(value === null || value === undefined) {
    return value
  } else if(Array.isArray(value)) {
    return value.map(v => removeTypename(v))
  } else if(typeof value === 'object') {
    const newObj = {}
    Object.entries(value).forEach(([ key, v ]) => {
      if(key !== '__typename')
        newObj[key] = removeTypename(v)
    })

    return newObj
  }

  return value
}

export const cleanErrorMessage = (message) => message.replace('GraphQL error: ','')

export function findObject(obj, callback) {
  for (var property in obj)
    if(obj.hasOwnProperty(property))
      if(typeof obj[property] == 'object') {
        const value = findObject(obj[property], callback)
        if(value) return value
      } else {
        const value = callback(obj[property])
        if(value) return value
      }

  return false
}

export function forEachObject(obj, callback) {
  for (var property in obj)
    if(obj.hasOwnProperty(property))
      if(typeof obj[property] == 'object')
        forEachObject(obj[property], callback)
      else
        callback(obj[property])
}

export const validateEmail = (email) => {
  if(!email) return false
  const regex = /^(([_a-z0-9-]+(\.[_a-z0-9-]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i

  return regex.test(email)
}

export const sleep = (ms) => new Promise(r => setTimeout(r, ms))

export const resolveAuthorization = async (file, openTestId, candidateId) => {
  const { data  } =  await Axios.post(
    `${process.env.REACT_APP_SERVER_URL}/storage/token`,
    {
      ContentType: file.type,
      Key        : `open-test/${openTestId}/${candidateId}/${file.name}`
    },
    {
      headers: {
        authorization: `Bearer ${getCredentials().accessToken}`,
        ...qs.parse(window.location.search)?.alloweditpostulation ? {
          alloweditpostulation: qs.parse(window.location.search)?.alloweditpostulation
        } : {}
      }
    })

  return data
}

export const uploadFile = async (file, openTestId, candidateId, onUploadProgress) => {
  if(!file)
    throw new Error('Archivo no valido')

  const { token, success } = await resolveAuthorization(file, openTestId, candidateId)

  if(!token || !success)
    throw new Error('No autorizado')

  const respAWS = await Axios.put(token, file, {
    headers: {
      'Content-Type': file.type,
      'x-amz-acl'   : 'public-read'
    },
    onUploadProgress
  })

  if(!respAWS) throw new Error('Error al subir a S3')

  return {
    mime: file.type,
    name: file.name,
    url : token.substr(0, token.indexOf('?'))
  }
}

export const parseTaskInput = (codeTask, section, countdown, duration, pointsAgainst) => {
  const contentTask = {
    countdown,
    duration,
    pointsAgainst,
    sections: section
  }

  return {
    alias   : AliasEnum.OPEN_TEST,
    codeTask: codeTask,
    contentTask
  }
}

export const getUsersCredentials = () => {
  const clientId = localStorage.getItem('clientId')
  const version = localStorage.getItem('version')

  return {
    clientId,
    version
  }
}

export const setUserCredentials = (clientId, version) => {
  const { clientId : ClientIdLocal } = getUsersCredentials()

  localStorage.setItem('version', version)

  if(Boolean(ClientIdLocal?.length)) return

  localStorage.setItem('clientId',clientId)
}

export const parseQuestions = (questions) => questions.map(question => {
  const modifiedOptions = question.options.map(option => ({ ...option, optionId: option._id }))

  return { ...question,
    options   : modifiedOptions,
    questionId: question._id }
})

export const parseSections = (sections) => {
  let newSections = []
  sections.forEach((section) => {
    const newSection = {
      _id      : section._id,
      completed: false,
      questions: parseQuestions(section.questions),
      sectionId: section._id,
      title    : section.title
    }

    newSections.push(newSection)
  })

  return newSections
}

export const mergeDate = (date, time) => {
  const day = date.getDate()
  const mounth = date.getMonth()
  const year = date.getFullYear()
  const hour = time.getHours()
  const minutes = time.getMinutes()
  const seconds = time.getSeconds()

  return new Date(year, mounth, day, hour, minutes, seconds)
}

export const omit = (obj, fields = []) => Object.keys(obj).reduce((acc, key) => {
  if(fields.includes(key)) return acc

  return {
    ...acc,
    [key]: obj[key]
  }
}, {})

import { gql } from 'apollo-boost'

export const VALID_AND_CREATE_NEW_RESULT_TASK = gql`
  mutation validAndCreateNewResultTask(
    $codeTask: String!
    $resultTaskId: ID!
    $actionRequestCode: String
  ) {
    validAndCreateNewResultTask(
      codeTask: $codeTask
      resultTaskId: $resultTaskId
      actionRequestCode: $actionRequestCode
    ) 
  }
`

import { useEffect, useState, useRef } from 'react'
import Account from 'controllers/Account'
import { useQuery } from '@apollo/client'
import { GET_EXTENSIONS_CONFIG } from 'apollo/open-test/types'
import { getSearchParams } from 'utils/searchParams'

const authController = new Account()

const { REACT_APP_BASE_FRONT_URL, REACT_APP_ACCOUNTS_FRONT_URL } = process.env

export const useConfigureVhViewportMobile = () => {
  useEffect(() => {
    const handleResize = () => {
      const vh = document.documentElement.clientHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }
    const vh = document.documentElement.clientHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
    window.addEventListener('resize',handleResize, false)

    return () => {
      window.removeEventListener('resize',handleResize, false)
    }
  },[])
}

export const useLoginRequired = () => {
  useEffect(() => {
    const main = async () => {
      try {
        const qsParams = getSearchParams()
        if(qsParams.alloweditpostulation) return

        const { pathname } = window.location
        /* OBTENER ACCESSTOKEN ACTUAL DE ACCOUNTS */
        const resAuthenticate = await authController.authenticate()

        if(!resAuthenticate.data.success)
          window.location.href = `${REACT_APP_ACCOUNTS_FRONT_URL}/login?urlRedirect=${REACT_APP_BASE_FRONT_URL}${pathname}&urlCallback=${REACT_APP_BASE_FRONT_URL}/callback`
      } catch (err) {
        // eslint-disable-next-line no-restricted-syntax
        console.log('Error al authenticar', err)
      }
    }
    main()
  },[])
}

export default function useDebounce(value, delay) {
  // State and setters for debounced value
  const [ debouncedTextValue, setDebouncedTextValue ] = useState(value)

  useEffect(
    () => {
      // Set debouncedValue to value (passed in) after the specified delay
      const handler = setTimeout(() => {
        setDebouncedTextValue(value)
      }, delay)

      // Return a cleanup function that will be called every time ...
      // ... useEffect is re-called. useEffect will only be re-called ...
      // ... if value changes (see the inputs array below).
      // This is how we prevent debouncedValue from changing if value is ...
      // ... changed within the delay period. Timeout gets cleared and restarted.
      // To put it in context, if the user is typing within our app's ...
      // ... search box, we don't want the debouncedValue to update until ...
      // ... they've stopped typing for more than 500ms.
      return () => {
        clearTimeout(handler)
      }
    },
    // Only re-call effect if value changes
    // You could also add the "delay" var to inputs array if you ...
    // ... need to be able to change that dynamically.
    [ delay, value ]
  )

  return debouncedTextValue
}

/**
 * Similar a useEffect pero no se ejecuta en el primer render
 * @param {*} effect
 * @param {*} deps
 */
export const useLazyEffect = (effect, deps) => {
  const firstRender = useRef(true)

  useEffect(() => {
    if(!firstRender.current)
      effect()
    else
      firstRender.current = false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}

export const useGetAllowedExtensionsConfig = ({ variables }) => {
  const { loading, error, data } = useQuery(GET_EXTENSIONS_CONFIG, { variables })

  return {
    data: data?.getExtensionsConfigByFileTypes,
    error,
    loading
  }
}


import { gql } from 'apollo-boost'
import { SECTION_FRAGMENT } from './fragment'

export const GET_OPEN_TEST_BY_ID = gql`
  query getOpenTestById($id: ID!){
    getOpenTestById(id: $id){
      _id
      timeoutConfirmation
      startedAt
      currentSection
      duration
      countdown
      finishedAt
      candidateId
      status
      taskId
      alias
      countdown
      sections {
        ...sectionFragment
      }
    }
  }
  ${SECTION_FRAGMENT}
`

export const GET_EXTENSIONS_CONFIG =  gql`
  query getExtensionsConfigByFileTypes($fileTypes: [ String! ]!) {
    getExtensionsConfigByFileTypes(fileTypes: $fileTypes) {
      sufix
      mime
      iconUrl
    }
  }
`

export const START_OPEN_TEST = gql`
  mutation startOpenTest($openTestId:ID!){
    startOpenTest(openTestId:$openTestId){
      _id
      status
      startedAt
    }
  }
`

export const FINISH_OPEN_TEST = gql`
  mutation finishOpenTest($openTestId:ID!){
    finishOpenTest(openTestId:$openTestId){
      _id
      status
      finishedAt
    }
  }
`

export const TIMEOUT_CONFIRM_OPEN_TEST = gql`
  mutation confirmTimeoutOpenTest($openTestId:ID!){
    confirmTimeoutOpenTest(openTestId:$openTestId){
      _id
      timeoutConfirmation
    }
  }
`

export const SAVE_ANSWER_OPEN_TEST = gql`
  mutation saveAnswer($openTestId:ID!, $questionId: ID!, $sectionId: ID!, $answer: AnswerOpenTestInput!){
    saveAnswer(openTestId:$openTestId, questionId: $questionId, sectionId: $sectionId, answer: $answer){
      _id
      sections {
        ...sectionFragment
      }
    }
  }
  ${SECTION_FRAGMENT}
`

export const COMPLETE_SECTION_OPEN_TEST = gql`
  mutation completeSection($openTestId:ID!, $sectionId: ID!){
    completeSection(openTestId:$openTestId, sectionId: $sectionId){
      _id
      sections {
        ...sectionFragment
      }
    }
  }
  ${SECTION_FRAGMENT}
`

export const CREATE_OPEN_TEST = gql`
  mutation createOpenTest($sections : JSON!){
    createOpenTest(sections: $sections){
      _id
    }
  }
`

import React from 'react'

import { useLoginRequired } from 'hooks'

const PrivateRoute = ({ children }) => {
  useLoginRequired()

  if(!children) return null

  return <>{children}</>
}

export default PrivateRoute


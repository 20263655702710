import { useEffect, useState } from 'react'
import { generatePath, matchPath, useHistory } from 'react-router-dom'
import { useValidAndCreateNewResultTask } from './apollo/hooks'
import qs from 'query-string'
import { useSnackbar } from 'notistack'

import { omit } from 'utils'

const ActionRequestFormValidator = ({ children }) => {
  const history = useHistory()
  const snackbarController = useSnackbar()

  const [ loading, setLoading ] = useState(true)

  const [ validAndCreateNewResultTask ] = useValidAndCreateNewResultTask()

  useEffect(() => {
    const main = async () => {
      try {
        const qsParams = qs.parse(window.location.search)

        if(!qsParams.actionRequestCode) return

        const match = matchPath(window.location.pathname, {
          exact: true,
          path : '/v2/:codeTask/:resultTaskId'
        })

        const { data: { validAndCreateNewResultTask: newResultTaskId } } = await validAndCreateNewResultTask({
          variables: {
            actionRequestCode: qsParams.actionRequestCode,
            codeTask         : match?.params?.codeTask,
            resultTaskId     : match?.params?.resultTaskId
          }
        })

        if(match?.params?.resultTaskId && match?.params?.resultTaskId !== newResultTaskId) {
          const newUrl = generatePath('/v2/:codeTask/:resultTaskId', {
            ...match.params,
            resultTaskId: newResultTaskId
          })

          const newSearch = qs.stringify({
            ...omit(qsParams, [ 'actionRequestCode' ])
          })

          history.replace({
            pathname: newUrl,
            search  : newSearch
          })
        }
      } catch (error) {
        snackbarController.enqueueSnackbar(error.message)
      } finally {
        setLoading(false)
      }
    }

    main()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if(loading) return null

  return children
}

export default ActionRequestFormValidator

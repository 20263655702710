export const LOCAL_STORAGE_KEYS = {
  ACCESS_TOKEN        : 'accessToken',
  EMAIL               : 'email',
  FIRSTNAME           : 'firstName',
  LASTNAME            : 'lastName',
  MIXPANEL_SESSION    : 'mixpanelSession',
  NUMBER_LOGINS       : 'numberLogins',
  REFRESH_TOKEN       : 'refreshToken',
  SEND_PROFILE_COUNTER: 'send_profile_counter',
  START_SESSION_DATE  : 'start_session_date',
  TOTAL_ERRORS        : 'total_errors',
  USER_ID             : 'iduser'
}

export const ParentSourceOrigin = {
  Gats  : 'GATS',
  Krocon: 'KROCON'
}

export const OpenTestQuestionEnum = {
  CUSTOM  : 'customAnswer',
  MULTIPLE: 'selectionMultiple',
  UNIQUE  : 'selectionUnique',
  UPLOAD  : 'fileUpload'
}

export const fileTypeEnum = {
  AUDIO       : 'Audio',
  DOC         : 'Word',
  IMAGE       : 'Imagen',
  PDF         : 'PDF',
  PRESENTATION: 'Presentaciones',
  SPREADSHEET : 'Hoja de cálculo',
  VIDEO       : 'Video'
}

export const QuestionFieldsEnum = {
  OPTIONS: 'options',
  POINTS : 'points',
  TYPE   : 'type'
}

export const FileTypeEnum = {
  File : 'file',
  Image: 'image'
}

export const TimeDebounceEnum = {
  DEBOUNCE_1000: 1000,
  DEBOUNCE_600 : 600,
  DEBOUNCE_800 : 800
}

export const OpenTestSectionActionsEnum = {
  ADD              : 'add',
  ADD_FROM_TEMPLATE: 'addFromTemplate',
  ADD_QUESTION     : 'addQuestion',
  DELETE           : 'delete',
  UPDATE           : 'update'
}

export const AttachmentTypeEnum = {
  ADD   : 'add',
  DELETE: 'delete'
}

export const CodeTaskEnum = {
  OPEN_TEST: 'open-test'
}

export const AliasEnum = {
  OPEN_TEST: 'Open test'
}

export const Colors = {
  blue : '#1a90ff',
  green: '#13BF5F'
}

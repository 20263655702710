import React, { useEffect, useMemo, useState } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import Root from './routes'
import * as serviceWorker from './serviceWorker'
import { SnackbarProvider } from 'notistack'
import dotenv from 'dotenv'
import { ApolloProvider } from '@apollo/client'
import { createApolloClient } from 'apollo/config'
import { ThemeProvider, krowdyTheme, createTheme } from '@krowdy/kds-core'
import queryString from 'query-string'
import { MuiPickersUtilsProvider } from '@ghondar/pickers'
import MomentUtils from '@date-io/moment'
import { getGradientPaletteColor } from 'utils/colors'
import produce from 'immer'

dotenv.config()

const App = () => {
  const [ colors, setColors ] = useState({
    custom   : krowdyTheme.palette.custom.main,
    primary  : krowdyTheme.palette.primary.main,
    secondary: krowdyTheme.palette.secondary.main
  })

  useEffect(() => {
    const queryParams = queryString.parse(window.location.search)
    setColors({
      custom   : queryParams.customColor ?? krowdyTheme.palette.custom.main,
      primary  : queryParams.primaryColor ?? krowdyTheme.palette.primary.main,
      secondary: queryParams.secondaryColor ?? krowdyTheme.palette.secondary.main
    })
  }, [])

  const theme = useMemo(() => {
    const theme = produce(krowdyTheme, (theme) => {
      for (const field of [ 'custom', 'primary', 'secondary' ])
        if(colors[field])
          theme['palette'][field] = {
            ...krowdyTheme.palette[field],
            ...getGradientPaletteColor(colors[field])
          }
    })

    return createTheme(theme)
  },[ colors ])

  return (
    <ApolloProvider
      client={createApolloClient()}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider
            anchorOrigin={{
              horizontal: 'center',
              vertical  : 'top'
            }}
            autoHideDuration={3000}
            maxSnack={1}>
            <Root />
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </ApolloProvider>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

// import { ApolloClient } from 'apollo-boost'
// import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient, InMemoryCache } from '@apollo/client'

import { ApolloLink } from 'apollo-link'
import { BatchHttpLink } from 'apollo-link-batch-http'
import { onError } from 'apollo-link-error'
import { disableFragmentWarnings } from 'graphql-tag'
import { resolvers } from '../resolvers'
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import introspectionQueryResultData from './fragmentTypes.json'
import qs from 'query-string'

import jstz from 'jstz'
import { getCredentials } from 'utils/credentials'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
})

disableFragmentWarnings()

const timezone = jstz.determine()

export function createApolloClient() {
  const cache = new InMemoryCache({
    fragmentMatcher
    // freezeResults: true
  })

  const batchLink = new BatchHttpLink({
    batchInterval: 50,
    headers      : {
      Authorization: `Bearer ${getCredentials().accessToken}`,
      ...qs.parse(window.location.search)?.alloweditpostulation ? {
        alloweditpostulation: qs.parse(window.location.search)?.alloweditpostulation
      } : {},
      timezone: timezone.name()
    },
    uri: `${process.env.REACT_APP_SERVER_URL}/graphql`
  })
  const errorHandler = onError(({ graphQLErrors, networkError }) => {
    if(graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) =>
        // eslint-disable-next-line no-restricted-syntax
        console.log(
          `[GraphQL error]: Message: ${message}, Location:` +
          JSON.stringify(locations) +
          `, Path: ${path}`
        )
      )

    if(networkError)
      // eslint-disable-next-line no-restricted-syntax
      console.log(`[Network error]: ${networkError}`)
  })

  const links = ApolloLink.from([ errorHandler, batchLink ])
  const client = new ApolloClient({
    // assumeImmutableResults: true,
    cache,
    link: links,
    resolvers
  })

  if(process.env.NODE_ENV === 'development')
    window.cache = client.cache.optimisticData.data

  return client
}

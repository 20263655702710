import { gql } from 'apollo-boost'

export const EXPERIENCE_FRAGMENT = gql`
  fragment experienceFragment on Experience {
    _id
    jobPosition
    companyName
    referent {
      _id
      actualPosition
      actualCompany
      email
      fullName
      phone
    } 
    hierarchy
    area
    location
    startDate
    endDate
    description
    imgUrl
    workHere
  }
`

export const SECTION_FRAGMENT = gql`
  fragment sectionFragment on Section {
    _id
    key
    name
    title
    questionType
    groupAlternatives
    customAnswers {
      _id
      alternatives {
        _id
        description
        response
        responseText
      }
      isPending
      label
      typeCustom
      kind
    }
    answers {
      ... on PickAnswer {
        _id
        correctAnswer
        points
        statement
        isPending
        chosenAlternative
        experienceId
        alternatives {
          _id
          chosen
          group
          description
          key
          scaleOrder
          value
        }
      }
      ... on ExperienceFormAnswer{
        _id
        isPending
        experienceId
        referent {
          _id
          actualPosition
          actualCompany
          email
          fullName
          phone
        }
      }
    }
  }
`

export const KNOWLEDGE_ANSWER_FRAGMENT = gql`
  fragment knowledgeAnswerFragment on KnowledgeAnswer {
    # questionType
    _id
    chosenAlternative
    points
    alternatives{
      _id
      value
      key
      description
    }
    statement
  }
`

export const LOCATION_FRAGMENT = gql`
  fragment locationFragment on LocationAnswer {
    _id
    value
    willing
  }
`

export const VIDEO_PRESENTATION_FRAGMENT = gql`
  fragment videoPresentationFragment on VideoPresentation {
    _id
    question
    videoResponseId
  }
`


import axios from 'axios'
import { getCredentials } from 'utils/credentials'

const { REACT_APP_ACCOUNTS_API_URL } = process.env
class Account {
  constructor() {
    this.instance = axios.create({
      baseURL: REACT_APP_ACCOUNTS_API_URL
    })
  }
  async authenticate() {
    try {
      const { accessToken } = getCredentials()

      const isAuthenticate = await this.instance.get('api/authenticate', {
        headers: {
          Authorization : `Bearer ${accessToken}`,
          'content-type': 'application/json'
        }
      })

      return isAuthenticate
    } catch (error) {
      if(error && error.response && error.response.status === 401) {
        // eslint-disable-next-line no-restricted-syntax
        console.log(`[Krowdy] - ONETAP : [${error.response.data.message}]`)

        return { data: { success: false } }
      }

      return { data: { success: false } }
    }
  }
}

export default Account

import React from 'react'
import { makeStyles } from '@krowdy/kds-core'

const Loading = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <img alt='krowdy-loader' height={150} src={'https://cdn.krowdy.com/images/loader.gif'} />
    </div>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    alignItems     : 'center',
    backgroundColor: 'white',
    display        : 'flex',
    height         : '100vh',
    justifyContent : 'center'
  }
}))

export default Loading

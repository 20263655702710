import { gql } from 'apollo-boost'

export const SECTION_FRAGMENT = gql`
  fragment sectionFragment on Section {
    _id
    sectionId
    title
    completed
    questions {
      isAllTypesAllowed
      isAllowedMany
      answer {
        attachments {
          _id
          name
          mime
          url
        }
        optionIds
        text
      }
      attachment {
        name
        type
        url
      }
      fileTypes
      fileConfigs {
        extensions {
          mime
          sufix
        }
        iconUrl
        type
      }
      options {
        attachment {
          name
          type
          url
        }
        correct
        optionId
        text
      }
      isAllowedMany
      isAllTypesAllowed
      fileTypes
      order
      questionId
      randomOrder
      required
      text
      title
      type
      maxOptionsSelected
      minOptionsSelected
    }
    introduction
  }
`

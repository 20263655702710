import { LOCAL_STORAGE_KEYS } from './constants'

/* export interface Credentials {
  accessToken: string;
  refreshToken: string;
  iduser: string;
} */

export const getCredentials = () => {
  const lsGetItem = (str) => window.localStorage.getItem(str) || localStorage.getItem(str)

  return {
    accessToken : lsGetItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN),
    iduser      : lsGetItem(LOCAL_STORAGE_KEYS.USER_ID),
    refreshToken: lsGetItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN)
  }
}
